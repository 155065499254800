<template>
  <iModal :mask-closable="false" @on-cancel="cancel" footer-hide :title="$t('lang.Create_a_Supply_Demand')" v-model="showNeeds" width="640">
    <div class="admin-add">
      <div class="admin-add-content">
        <Form :label-width="120" @submit.native.prevent label-position="right">
          <FormItem label="Type：">
            <div class="btnwrap">
              <Button :class="activeShow == index ? 'primary' : ''" :key="index" @click="changeCategory(index, item)" class="btn" v-for="(item, index) in typeTabs">{{ item }}</Button>
            </div>
          </FormItem>
          <FormItem label="Product：">
            <Input v-if="activeShow === 0"  :placeholder="$t('lang.Please_add_product_name')" v-model="productName" />
            <Select class="select_wrap" v-model="productName" v-else placeholder="Please select product" >
              <Option v-for="item in proList" :value="item.name" :key="item.name">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="Description：" style="margin-bottom: 0;">
            <Input :rows="4" :placeholder="$t('lang.Please_add_a_brief_description')" style="margin-bottom:20px;" type="textarea" v-model="content" />
          </FormItem>
          <FormItem label="Upload：">
            <div>
              <uploadNeed ref="uploadNeed" :fileList="uploadBanner" @upload-ok="bannerOK" :isMax="true" />
            </div>
            <p class="upload-p" style="width: 100%;font-size: 12px;color: #4d4d4d;">(5 pictures or 1 video at most)</p>
            <!-- <upload :config="uploadBannerConfig" :fileList="uploadBanner" @upload-ok="bannerOK" ref="upload1" /> -->
          </FormItem>
        </Form>
      </div>
      <div class="f-tac">
        <Button @click="cancel" style="margin-right: 20px;width: 130px">Cancel</Button>
        <Button @click="handleSubmit" style="width: 130px;" type="primary">Submit</Button>
      </div>
    </div>
  </iModal>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import uploadNeed from "@components/upLoad/upLoadNeed";
export default {
  data() {
    return {
      showTabs: "1",
      showNeeds: false,
      typeTabs: [
        "Purchase",
        "Supply",
        // '征求服务'免费发布供需
      ],
      activeShow: 0,
      selectTab: "",
      content: "",
      productName: "",
      proList:[],
      uploadBanner: [], // 活动图
      config: {
        fileType: "img",
        headers: {},
        action: "",
        format: ["jpg", "jpeg", "png"],
        "max-size": 2048,
        remind: "Requirements: .jpg/png within 2M, up to 5 pictures",
        limitNum: 5,
      },
      editModel: false,
      editId: "",
    };
  },
  computed: {
    ...mapGetters({
      formHeaders: "getFormHeaders",
      uploadUrl: "getUploadUrl",
      userInfo: "getUser",
      exhibitionInfo: "getExhibitionInfo",
      exhibitionId: "getExhibitionId",
      login: "getLogin",
    }),
    uploadBannerConfig() {
      let config = JSON.parse(JSON.stringify(this.config));
      config.id = "upload_1";
      config.headers = this.formHeaders;
      config.action = this.uploadUrl;
      config.remind = "Requirements: .jpg/png within 2M, up to 5 pictures";
      return config;
    },
    isRegOk() {
      return this.userInfo && this.userInfo.inMember && this.userInfo.inMember.userRole && this.userInfo.inMember.userRole != "VISITOR" && this.userInfo.inMember.userRole != "Visitor";
    },
    userRole() {
      return this.userInfo && this.userInfo.inMember && this.userInfo.inMember.userRole ? this.userInfo.inMember.userRole : "";
    },
  },
  components: {
    uploadNeed,
  },
  created() {
    if(this.userRole == "EXHIBITOR"){
      this.getProduct();
    }
  },
  methods: {
    ...mapActions({
      supplyDemandSubmit: "news/supplyDemand",
      editSupply: "news/editSupply",
      unverifiedDialog: "unverifiedDialog",
      graphqlPost: "graphqlPostByZXSEn",
    }),
    async getProduct(){
      let query = `
          query{
            exhibitorQuery{
              get(id:"${this.userInfo.inMember.exhibitorId}"){
                  products(num:10000){
                    name
                    id
                    nameEn 
                  }
                }
              }
          }
        `;
        let opt = {
          query: query,
          variables: {},
        };
        let res = await this.graphqlPost(opt);
        console.log(res, "exinfo res");
        this.proList=res.data.exhibitorQuery.get.products
    },
    changeCategory(index, item) {
      if (this.typeTabs[index] === "Supply" && this.userRole !== "EXHIBITOR") {
        this.$Message.warning("Only exhibitors can post supply information!");
        return;
      }
      this.activeShow = index;
      this.selectTab = item;
      this.productName='';
    },
    open(item = null) {
      
      if (!(this.userInfo && this.userInfo.userId && this.userInfo.email)||this.userInfo.inMember.userRole=='VISITOR') {
        this.unverifiedDialog(this);
        return;
      }
      this.showNeeds = true;
      if (item) {
        // 编辑模式
        this.editModel = true;
        this.editId = item.id;
        if(item.imgUrls&&item.imgUrls.length){
          let arr=[];
          item.imgUrls.forEach(e=>{
            arr.push({
              url:e
            })
          })
          this.uploadBanner = arr;
          this.$nextTick(()=>{
            this.$refs.uploadNeed.editType('image')
          })
        }else if(item.videos&&item.videos.length){
           this.uploadBanner =[item.videos[0]]
          this.$nextTick(()=>{
            this.$refs.uploadNeed.editType('video')
          })
        }
        this.selectTab = item.type;
        this.typeTabs.forEach((c, i) => {
          if (c == this.selectTab) {
            this.activeShow = i;
          }
        });
        this.content = item.content;
        this.productName = item.productName;
      }
    },
    bannerOK(list) {
      this.uploadBanner = list;
    },
    async handleSubmit() {
      if (!this.login || !this.isRegOk) {
        this.unverifiedDialog(this);
        return;
      }
      if (!this.content) {
        this.$Message.warning("Please enter the Content");
        return;
      }
      if (!this.productName) {
        if(this.activeShow==0){
          this.$Message.warning("Please add product name");
        }else{
          this.$Message.warning("Please select product");
        }
        return;
      }
      if (!this.editModel) {
        this.content=this.content.split("\n").join("<br>");
        // 新增模式
        let opt = {
          memberId: this.userInfo.id,
          content: this.content,
          productName: this.productName,
          publisherType: 0,
          originName: this.exhibitionInfo.name || "平台",
          exhibitionId: this.exhibitionId,
          type: this.selectTab || "Purchase",
          langVersion: "en",
        };
        if (this.uploadBanner.length) {
          if(this.uploadBanner[0].cover){
            opt.videos=`[{"cover":"${this.uploadBanner[0].cover}","url":"${this.uploadBanner[0].url}","duration":"","coverIndex": 5}]`
          }else{
            let arr=[]
            this.uploadBanner.forEach(e=>{
              arr.push(e.url);
            })
            opt.imgUrls = arr.join(";");
          }
        }
        let data = await this.supplyDemandSubmit(opt);
        if (data && data.result) {
          this.$Message.success("Success");
          this.$emit("finish");
        } else {
          this.$Message.error("Fail");
        }
        this.cancel();
      } else {
        // 编辑模式
        let opt = {
          id: this.editId,
          content: this.content,
          productName: this.productName,
          auditState: 0,
          type: this.selectTab || "Purchase",
        };
        if (this.uploadBanner.length) {
          if(this.uploadBanner[0].cover){
            opt.videos=`[{"cover":"${this.uploadBanner[0].cover}","url":"${this.uploadBanner[0].url}","duration":"","coverIndex": 5}]`
            opt.imgUrls=''
          }else{
            let arr=[]
            this.uploadBanner.forEach(e=>{
              arr.push(e.url);
            })
            opt.imgUrls = arr.join(";");
            opt.videos=''
          }
        }else{
          opt.imgUrls=''
          opt.videos=''
        }
        let res = await this.editSupply(opt);
        if (res.result) {
          this.$Message.success("Success");
          this.$emit("finish");
        } else {
          this.$Message.error("Fail");
        }
        this.cancel();
      }
    },
    cancel() {
      this.showNeeds = false;
      this.content = "";
      this.productName = "";
      this.uploadBanner = [];
      this.showTabs = "1";
    },
  },
};
</script>
<style lang="scss" scoped>
.select_wrap{
  &::v-deep .ivu-select-dropdown{
    width: 268px;
  }
}
@import "@assets/scss/my-theme/mixin.scss";
.btn {
  margin-right: 12px;
  width: 110px;
  &:last-child {
    margin-right: 0;
  }
}
.primary {
  color: #fff;
  @include background_color(#2d8cf0);
  @include border_color(#2d8cf0);
}
.admin-add-content {
  padding-right: 30px;
}
.btnwrap {
  display: flex;
  button {
    flex: 1;
    background-color: #f7f7f7;
  }
}
</style>
