import { render, staticRenderFns } from "./needsModel.vue?vue&type=template&id=efd57354&scoped=true&"
import script from "./needsModel.vue?vue&type=script&lang=js&"
export * from "./needsModel.vue?vue&type=script&lang=js&"
import style0 from "./needsModel.vue?vue&type=style&index=0&id=efd57354&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efd57354",
  null
  
)

export default component.exports